import * as React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SiteContainer from "../../components/site-container";
import {graphql, Link} from "gatsby";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

const PrivacyPage = ({ data }) => {
    const groups = data.allLever.group;

    return (
        <SiteContainer>
            <Helmet>
                <title>Careers at Firstparty</title>
                <meta name="description" content="We love our fully-remote, asynchronous work style with a focus on objective-backed results." />
                <link rel="icon"
                      href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>"/>
            </Helmet>
            <section className="py-8 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7 text-center">
                            <h2 className="display-2 fw-bold text-black">
                                Careers at Firstparty
                            </h2>
                            <p className="lead mb-6">
                                We love our fully-remote, asynchronous work style with a focus on objective-backed results.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-light pt-10 pb-5">
                <Container>
                    <Row className="mb-8">
                        <Col xs={12} className="text-center">
                            <h2>
                                Current Open Positions
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {groups.map(function(jobs) {
                                return (
                                    <>
                                        <div className="row align-items-center mb-5">
                                            <div className="col">
                                                <h4 className="fw-bold mb-1">
                                                    {jobs.nodes[0].categories.team}
                                                </h4>
                                            </div>
                                            <div className="col-auto">
                                                <span className="badge rounded-pill bg-success-soft">
                                                    <span className="h6 text-uppercase">{jobs.nodes.length} Open {jobs.nodes.length > 1 ? "Positions" : "Position"}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive mb-7">
                                                    <table className="table table-align-middle">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                <span className="h6 text-uppercase fw-bold">
                                                                Role
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="h6 text-uppercase fw-bold">
                                                                Team
                                                                </span>
                                                            </th>
                                                            <th>
                                                                <span className="h6 text-uppercase fw-bold">
                                                                Location
                                                                </span>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {jobs.nodes.map(function(job) {
                                                            return <tr>
                                                                <td>
                                                                    <a href={`/careers/${job.id}/`}
                                                                        className="text-reset text-decoration-none">
                                                                        <p className="mb-1">
                                                                            {job.text}
                                                                        </p>
                                                                        {/*<p className="fs-sm text-muted mb-0">*/}
                                                                        {/*    Responsible for design systems and brand management.*/}
                                                                        {/*</p>*/}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a href={`/careers/${job.id}/`}
                                                                        className="text-reset text-decoration-none">
                                                                        <p className="fs-sm mb-0">
                                                                            {job.categories.team}
                                                                        </p>
                                                                    </a>

                                                                </td>
                                                                <td>
                                                                    <a href={`/careers/${job.id}/`}
                                                                        className="text-reset text-decoration-none">
                                                                        <p className="fs-sm mb-0">
                                                                            {job.categories.location}
                                                                        </p>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="bg-light py-10">
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col xs={12} md={6}>
                            <h2>
                                About Firstparty
                            </h2>
                            <p className="text-muted mb-6 mb-md-0">
                                Firstparty is an early-stage B2B software startup. Our customers are marketers and software
                                developers who are looking for tools to help them customize user experiences within their
                                products and across the Internet. Using Firstparty, they collect event-based information
                                about user activity and utilize this data to build custom product workflows, marketing
                                automation, and better performing advertising campaigns.
                            </p>
                        </Col>
                        <Col xs={12} md={6}>
                            <h2>
                                How We Operate
                            </h2>
                            <p className="text-muted mb-6 mb-md-0">
                                Our work style is fully remote and asynchronous, with a focus on results. This means we do
                                not require any set schedule or set number of hours from our team members, and instead
                                focus on setting clear achievable goals we can work toward together. We have very few
                                meetings, and rely on written communication for our meeting agendas, conversations,
                                decisions, and process documentation.
                            </p>

                        </Col>
                    </Row>
                </Container>
            </section>
        </SiteContainer>
    )
}

export const pageQuery = graphql`
  query {
    allLever {
    group(field: categories___team) {
      nodes {
        categories {
          team
          commitment
          level
          location
        }
        additional
        applyUrl
        createdAt
        description
        id
        lever_id
        text
      }
    }
  }
  }
`
export default PrivacyPage
